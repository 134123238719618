fieldset {
  @include contain-floats();
  width: 100%;

  &.after-error-summary {
    margin-top: -$gutter-half;

    @include media(tablet) {
      margin-top: -$gutter;
    }
  }
}

legend {
  overflow: hidden;
}

textarea {
  display: block;
}


// Form wrappers

.form-section,
.form-group {
  @include contain-floats();
  @include box-sizing(border-box);
}

// Form section is used to wrap .form-group and has twice its margin
.form-section {
  margin-bottom: $gutter;

  @include media(tablet) {
    margin-bottom: $gutter * 2;
  }
}

// Form group is used to wrap label and input pairs
.form-group {
  margin-bottom: $gutter-half;

  @include media(tablet) {
    margin-bottom: $gutter;
  }
}

// Form group related is used to reduce the space between label and input pairs
.form-group-related {
  margin-bottom: 10px;

  @include media(tablet) {
    margin-bottom: 20px;
  }
}

// Form group compound is used to reduce the space between label and input pairs
.form-group-compound {
  margin-bottom: 10px;
}


// Form labels
.form-label,
.form-label-bold,
.form-label-b19 {
  display: block;
  color: $text-colour;
  padding-bottom: 0;
  margin-bottom: 10px;;
}

.form-label {
  @include core-19;
}

.form-label-b19 {
  @include bold-19;
  span {
    @include core-16;
  }
}

.form-label-bold {
  @include bold-24;
}

.form-block {
  float: left;
  clear: left;

  margin-top: -5px;
  margin-bottom: 5px;

  @include media(tablet) {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

// Form hints
.form-hint {
  @include core-19;
  display: block;
  color: $secondary-text-colour;
  font-weight: normal;
  margin-bottom: 10px;
  margin-top: 0;
  padding-bottom: 0;
}

.form-label .form-hint,
.form-label-bold .form-hint {
  margin-top: 0;
  padding-bottom: 0;
}

// Form controls
// By default, form controls are 50% width for desktop,
// and 100% width for mobile
.form-control {
  @include box-sizing(border-box);
  @include core-19;
  width: 100%;

  padding: 5px 4px 4px;
  border: 2px solid $text-colour;

  @include media(tablet) {
    width: 50%;
  }

}

input.form-control,
textarea.form-control {
  -webkit-appearance: none;
  border-radius: 0;
}

textarea.form-control {
  opacity: 1;
  background-image: none;
  width: 100%!important;
}

// Form control - radio buttons

.radio-wrapper {
  position: relative;
  padding: 0 0 0 38px;
  margin-bottom: 10px;

  input[type="radio"] {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: -2px;
    left: -2px;
    width: 44px;
    height: 44px;
    margin: 0;
    opacity: 0;
    &:focus + label::before {
      border-width: 4px;
      box-shadow: 0 0 0 4px #fd0;
    }
  }

  input[type="radio"] ~ label {
    padding: 7px 10px 7px 12px;
    font-weight: normal;
    font-size: 19px;
    display: inline-block;
    cursor: pointer;
    font-family: 'helvetica';
    &::before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      border: 2px solid currentColor;
      border-radius: 50%;
      background: transparent;
    }
    &::after {
      content: "";
      content: "";
      position: absolute;
      top: 10px;
      left: 10px;
      width: 0;
      height: 0;
      border: 10px solid currentColor;
      border-radius: 50%;
      opacity: 0;
      background: currentColor;
    }
  }

  input[type="radio"]:checked ~ label {
    &::after {
      zoom: 1;
      opacity: 1;
    }
  }
}

// Form control - checkbox
.checkbox-wrapper {
  position: relative;
  padding: 0 0 0 38px;
  margin-bottom: 10px;

  input[type="checkbox"] {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: -2px;
    left: -2px;
    width: 44px;
    height: 44px;
    margin: 0;
    opacity: 0;
    &:focus + label::before {
      border-width: 4px;
      box-shadow: 0 0 0 4px #fd0;
    }
  }

  input[type="checkbox"] ~ label {
    padding: 7px 10px 7px 12px;
    font-weight: normal;
    font-size: 19px;
    display: inline-block;
    cursor: pointer;
    &::before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      border: 2px solid currentColor;
      background: transparent;
    }
    &::after {
      content: "";
      position: absolute;
      top: 11px;
      left: 9px;
      width: 18px;
      height: 7px;
      transform: rotate(-45deg);
      border: solid;
      border-width: 0 0 5px 5px;
      border-top-color: transparent;
      opacity: 0;
      background: transparent;
    }
    &.form-label-b19 {
      @include bold-19;
      span {
        @include core-16;
      }
    }
  }

  input[type="checkbox"]:checked ~ label {
    &::after {
      zoom: 1;
      opacity: 1;
    }
  }
  label{
    .vc-visually-hidden{
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      margin: 0 !important;
      padding: 0 !important;
      overflow: hidden !important;
      clip: rect(0 0 0 0) !important;
      -webkit-clip-path: inset(50%) !important;
      clip-path: inset(50%) !important;
      border: 0 !important;
      white-space: nowrap !important;
    }
  }
}

.checkbox-wrapper.multi-select{
  padding: 0;
  margin: 10px 0 5px;
  input[type=checkbox] ~ label{
    &:before{
    width: 24px;
    height: 24px;
    }
    &:after{
    top: 7px;
    left: 6px;
    width: 9px;
    height: 3.5px;
    border-width: 0 0 3px 3px;
    }
  }
}

.inlineRadioButtons {
  display: inline-block;
  margin: 0 20px 0 0;
}

.form-label-count {
  color: $grey-1;
  padding-bottom: 0;
  margin: 5px 0 0 0;
}

// Form control widths

.form-control-1-1 {
  width: 74.5%;

}

.form-control-3-4 {
  width: 100%;

  @include media(tablet) {
    width: 75%;
  }
}

.form-control-2-3 {
  width: 100%;

  @include media(tablet) {
    width: 66.66%;
  }
}

.form-control-1-2 {
  width: 100%;

  @include media(tablet) {
    width: 50%;
  }
}

.form-control-1-3 {
  width: 100%;

  @include media(tablet) {
    width: 33.33%;
  }
}

.form-control-1-4 {
  width: 100%;

  @include media(tablet) {
    width: 25%;
  }
}

.form-control-1-8 {
  width: 100%;

  @include media(tablet) {
    width: 12.5%;
  }
}

.form-control-2-1 {
  width: 100%;

  @include media(tablet) {
    width: 40%;
  }
}

option:active,
option:checked,
select:focus::-ms-value {
  color: $white;
  background-color: $main-blue;
}


// form control - file input
input[type=file] {
  border: 2px solid transparent;
}